import Img from 'gatsby-image'
import get from 'lodash/get'
import React, { useState } from 'react'
import Helmet from 'react-helmet'
import Footer from '../components/footer'
import JcWeek from '../components/jc-week'
import Layout from '../components/layout'
import RichText from '../components/rich-text'
import Disqus from '../components/shared/Disqus'
import HeaderImage from '../images/header.png'
import JELogo from '../images/journey-east-logo.svg'
import styles from './index.module.css'

function Step({ title, img, desc }) {
  return (
    <div className={styles.step}>
      <h2 className={styles.stepTitle}>{title}</h2>
      <Img
        className={styles.stepIllo}
        fluid={img.childImageSharp.fluid}
        alt=""
      />
      <p className={styles.stepDesc}>{desc}</p>
    </div>
  )
}

export default function Challenge(props) {
  const [email, setEmail] = useState('')

  function onInputEmail({ target: { defaultValue } }) {
    setEmail(defaultValue)
  }

  const weeks = get(props, 'data.allContentfulIssue.edges', []).map(
    edge => edge.node
  )
  const about = get(props, 'data.allContentfulAbout.edges')[0].node

  return (
    <Layout location={props.location}>
      <div className={styles.challenge}>
        <Helmet title="Journey East">
          <meta property="og:title" content="Journey East" />
          <meta property="og:type" content="website" />
          <meta
            property="og:description"
            content="A digital zine about the food of the Chinese diaspora."
          />
          <meta
            property="og:image"
            content="https://journeyeast.co/static/bc74c1ea2de3a99286bd1cb5f6928bd1/88d05/illo1.png"
          />
          <meta property="og:image:width" content="800" />
          <meta property="og:image:height" content="636" />
          <meta property="og:url" content="https://journeyeast.co/" />
        </Helmet>

        <section className={styles.signup}>
          <div className={styles.headerImgContainer}>
            <img className={styles.logoImg} src={JELogo} alt="" />
            <img className={styles.headerImg} src={HeaderImage} alt="" />
            {/*<div className={styles.insta}>
              <FaInstagram size={24} color="#fff" />
              &nbsp;
              <a
                className={styles.instalink}
                href="http://instagram.com/journeyeastzine"
                target="_blank"
              >
                journeyeastzine
              </a>
            </div>*/}
          </div>
          <div className={styles.content}>
            <div className={styles.newsletterCaption}>
              Get new issues to your inbox:
            </div>
            <div className={styles.newsletter}>
              <form
                action="https://journeyeast.us4.list-manage.com/subscribe/post"
                method="POST"
                className={styles.emailForm}
              >
                <input
                  type="hidden"
                  name="u"
                  defaultValue="9786728ce5b7b2c3b7e0fae52"
                />
                <input type="hidden" name="id" defaultValue="aaa8fa0b51" />
                <div
                  className={styles.fieldShift}
                  aria-label="Please leave the following three fields empty"
                >
                  <label htmlFor="b_name">Name: </label>
                  <input
                    type="text"
                    name="b_name"
                    tabIndex="-1"
                    defaultValue=""
                    placeholder="Freddie"
                    id="b_name"
                  />

                  <label htmlFor="b_email">Email: </label>
                  <input
                    type="email"
                    name="b_email"
                    tabIndex="-1"
                    defaultValue=""
                    placeholder="youremail@gmail.com"
                    id="b_email"
                  />

                  <label htmlFor="b_comment">Comment: </label>
                  <textarea
                    name="b_comment"
                    tabIndex="-1"
                    placeholder="Please comment"
                    id="b_comment"
                  />
                </div>

                <input
                  name="MERGE0"
                  id="MERGE0"
                  className={styles.email}
                  type="text"
                  onChange={onInputEmail}
                  placeholder="email address"
                  aria-label="Email Address"
                />
                <input
                  type="submit"
                  name="submit"
                  value="Subscribe"
                  className={styles.subscribe}
                ></input>
              </form>
            </div>
          </div>
        </section>

        <section
          className={styles.whyStartHeader}
          style={{ marginTop: '120px' }}
        >
          <h2 className={styles.weeklyMenus}>LATEST ISSUE</h2>
          <div className={styles.weeklyMenusRule} />
        </section>
        <section className={styles.flexColCenter}>
          <JcWeek week={weeks[0]} key={`week-${weeks[0].number}`} />
        </section>

        <section
          className={styles.whyStartHeader}
          style={{ marginTop: '120px' }}
        >
          <h2 className={styles.weeklyMenus}>WHAT YOU'LL FIND HERE</h2>
          <div className={styles.weeklyMenusRule} />
        </section>

        <section className={styles.steps}>
          <Step
            title="Explore food culture"
            img={get(props, 'data.illo1')}
            desc={
              <span>
                Subscribe via email or follow{' '}
                <a href="http://instagram.com/journeyeastzine" target="_blank">
                  @journeyeastzine
                </a>{' '}
                to following along with recipes, stories, fun facts, and more.
              </span>
            }
          />
          <Step
            title="Learn home cooking"
            img={get(props, 'data.illo2')}
            desc="If you'd like, give the recipes a try–creativity is encouraged!"
          />
          <Step
            title="Join the discussion"
            img={get(props, 'data.illo3')}
            desc={
              <span>
                Ask questions, share photos, and join the discussion in the
                comments/on Instagram–we'd love to hear what you have to say!
              </span>
            }
          />
        </section>

        <section className={styles.whyStartHeader}>
          <h2 className={styles.weeklyMenus}>WHY THIS ZINE?</h2>
          <div className={styles.weeklyMenusRule} />
        </section>

        <section className={styles.whyStart}>
          <RichText field={about.challenge} />
        </section>

        <section className={styles.weeklyMenusHeader} anchor="menus">
          <h2 className={styles.weeklyMenus}>PAST ISSUES</h2>
          <div className={styles.weeklyMenusRule} />
        </section>

        <section className={styles.flexColCenter}>
          {weeks.slice(1).map(week => (
            <JcWeek week={week} key={`week-${week.number}`} />
          ))}
        </section>
      </div>
      <Disqus identifier="zine-homepage" title="Journey East Zine" />
      <Footer />
    </Layout>
  )
}

export const pageQuery = graphql`
  query Home {
    backgrounds: allFile(
      filter: { sourceInstanceName: { eq: "backgrounds" } }
    ) {
      nodes {
        relativePath
        childImageSharp {
          fluid(maxWidth: 3000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    illo1: file(
      relativePath: { eq: "illo1.png" }
      sourceInstanceName: { eq: "challenge" }
    ) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    illo2: file(
      relativePath: { eq: "illo2.png" }
      sourceInstanceName: { eq: "challenge" }
    ) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    illo3: file(
      relativePath: { eq: "illo3.png" }
      sourceInstanceName: { eq: "challenge" }
    ) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allContentfulAbout {
      edges {
        node {
          challenge {
            json
          }
          principles {
            json
          }
        }
      }
    }
    allContentfulIssue(sort: { fields: [number], order: DESC }) {
      edges {
        node {
          title
          issueDate
          number
          tagline
          recipes {
            title
          }
          content {
            json
          }
          illustration {
            fluid(maxWidth: 1048) {
              ...GatsbyContentfulFluid
            }
          }
          region
        }
      }
    }
  }
`
